.saving-error-modal {
  z-index: 11100;
  .canvas-modal__wrapper {
    background-color: white;
  }
}

.saving-error-modal__body {
  width: 500px;
  overflow: auto;
}

.not-allowed-modal__body {
  max-width: 80vw;
  min-width: 40vw;
}
