.canvas-modal {
  --canvas-modal-min-w: 400px;
  position: relative;
  z-index: 11100;
}

.canvas-modal__overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  &.animate-open {
    animation: blur 1s ease forwards;
  }
  &.animated {
    background-color: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(3px);
  }
}
@keyframes blur {
  to {
    background-color: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(3px);
  }
}

.canvas-modal__wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-y: auto;
}

.canvas-modal__inner {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  min-height: 100vh;
}

.canvas-modal__panel {
  position: relative;
}

.canvas-modal__body {
  background-color: var(--white);
  border-radius: 12px;
  overflow: hidden;
  min-width: var(--canvas-modal-min-w, 400px);
  min-height: var(--canvas-modal-min-h, 0);
  box-shadow: 0px 0px 12px 8px rgba(0, 0, 0, 0.2);
}

.canvas-modal__close-btn {
  position: absolute;
  top: -48px;
  right: -48px;
  padding: 8px;
  border: 1px solid var(--gray-200);
  border-radius: 32px;
  background-color: var(--white);
  color: var(--theme-highlight-color);
}

.canvas-modal__header {
  text-align: center;
  padding: 4px 8px;
  border-bottom: 1px solid var(--gray-200);
}
