.starting-node {
  cursor: initial;
  &__title {
    width: 100%;
    font-size: 26px;
    line-height: 1.5;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__avatar {
    width: 100%;
    height: 125px;
    object-fit: contain;
  }

  &__content {
    position: relative;
    flex: 1;
  }

  &__bg-placeholder {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);

    svg {
      width: 100%;
    }
  }

  &__button-title,
  &__button_label-input {
    width: 100%;
    font-size: 14px;
    line-height: 1.5;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    background-color: var(--theme-base-color);
    border: 2px solid var(--theme-base-color);
  }

  &__button-title {
    outline: 1px solid transparent;
    border-radius: 4px;
  }

  &__button_label-input:focus-visible {
    outline: none;
  }

  &__play-btn {
    position: absolute;
    width: 100px;
    height: 100px;
    padding: 0;
    border-radius: 100px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: transparent;
    border: 0;
  }

  &__footer {
    margin-top: auto;
  }

  &__cta-btn {
    width: 100%;
    padding: 10px 0;
    // font-size: 14px;
    color: var(--theme-text-color);
    border-radius: 8px;
    background-color: var(--theme-base-color);
    border: 2px solid transparent;

    &:hover,
    &:focus,
    &:active {
      border-color: var(--theme-highlight-color);
    }
  }
}
