.new-outcome-modal {
  .canvas-modal__body {
    min-width: 560px;
  }
}

.new-outcome-modal__header {
  display: flex;
  align-items: center;
  padding: 16px 8px 8px;

  .new-outcome-modal__back-button {
    color: var(--body-color);
    font-weight: 600;

    &:hover {
      color: var(--theme-highlight-color);
    }
  }
}

.new-outcome-modal__heading {
  font-size: 24px;
  font-weight: bold;
  flex: 1;
  padding-right: 60px;
}

.new-outcome-modal__body {
  min-height: 150px;
  padding: 24px 22px;
  display: flex;
  align-items: center;
}

.new-outcome-modal__content {
  flex: 1 0 auto;
  margin: 15px 0 70px 0;

  .input-text-field__label {
    padding-left: 0;
    font-weight: 600;
  }

  .new-outcome-modal__switch {
    margin-top: 20px;

    &-label {
      margin-bottom: 12px;
      font-weight: 600;
    }
  }

  .new-outcome-modal__url-input-field {
    margin-top: 22px;
  }
}

.new-outcome-modal__continue-btn {
  font-size: 21px;
  padding: 12px 24px;
  background-color: var(--theme-base-color);
  color: var(--theme-text-color);
  width: 100%;
  height: 52px;
}

.new-outcome-modal__footer {
  min-height: 52px;
}
