.node-title {
  position: absolute;
  top: -20px;
  margin-bottom: 12px;

  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #a9a9a9;
}
