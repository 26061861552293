.change-weighting-modal {
  .canvas-modal__body {
    min-width: 560px;
  }
}

.change-weighting-modal__header {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 8px 8px;
}

.change-weighting-modal__heading {
  font-size: 24px;
  font-weight: bold;
}

.change-weighting-modal__body {
  min-height: 150px;
  padding: 24px 22px;
  display: flex;
  align-items: center;
}

.change-weighting-modal__content {
  flex: 1 0 auto;
  margin: 15px 0 70px 0;

  .input-text-field__label {
    padding-left: 0;
    font-weight: 600;
  }

  .change-weighting-modal__url-input-field {
    margin-top: 22px;
  }
}

.change-weighting-modal__continue-btn {
  font-size: 21px;
  padding: 12px 24px;
  background-color: var(--theme-base-color);
  color: var(--theme-text-color);
  width: 100%;
  height: 52px;
}

.change-weighting-modal__footer {
  display: flex;
  flex-direction: row;
  min-height: 52px;

  .change-weighting-modal__continue-btn:first-child {
    border-right: 1px solid #fff;
  }

  svg {
    padding-left: 8px;
  }
}
