.name-your-video-modal {
  .canvas-modal__body {
    min-width: 560px;
  }
}

.name-your-video-modal__header {
  display: flex;
  justify-content: space-between;
  padding: 15px 21px;

  div {
    width: 50px;
  }
}

.name-your-video-modal__heading {
  font-size: 22px;
  font-weight: bold;
}

.name-your-video-modal__back-btn {
  display: flex;
  align-items: flex-end;
  gap: 9px;
  font-size: 12px;
  font-weight: 600;
  color: var(--theme-highlight-color);

  svg {
    font-size: 16px;
  }
}

.name-your-video-modal__body {
  min-height: 150px;
  padding: 48px 24px;
  display: flex;
  align-items: center;
}

.name-your-video-modal__content {
  flex: 1 0 auto;
}

.name-your-video-modal__continue-btn {
  display: flex;
  gap: 4px;
  justify-content: center;
  font-size: 21px;
  letter-spacing: 1.75px;
  padding: 12px 24px;
  background-color: var(--theme-base-color);
  color: var(--theme-text-color);
  width: 100%;
  height: 52px;
}

.name-your-video-modal__footer {
  min-height: 52px;
}
