$parent: node-card-side-action;

.node-card-side-action {
  --node-card-side-size: 130px;
  position: absolute;
  display: flex;
  align-items: flex-end;
  justify-content: end;
  padding: 16px;
  width: var(--node-card-side-size);
  height: var(--node-card-side-size);
  border-radius: 40px;
  z-index: -1;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: var(--gray-200);
    // clip-path: polygon(100% 0, 0% 100%, 100% 100%);
    // // border-radius: 25% 25% 25% 25% / 25% 25% 25% 25%;
    clip-path: polygon(169px 0, 0% 169px, 100% 100%);
    border-radius: 40px;
    z-index: -1;
  }

  &--bottom {
    left: 50%;
    bottom: -40px;
    transform: translateX(-50%) rotate(45deg);
    z-index: 0;
  }

  &--right {
    top: 50%;
    right: -40px;
    transform: translateY(-50%) rotate(-45deg);
  }
}

.node-card-side-action__wrapper {
  position: relative;
  background-color: #fff;
  border-radius: 50%;
  padding: 1px;

  .#{$parent}--bottom & {
    transform: rotate(-45deg);
  }

  .#{$parent}--right & {
    transform: rotate(45deg);
  }
}

.node-card-side-action__action {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  color: var(--theme-highlight-color);
  border-radius: 100px;
  background-color: var(--white);

  &:hover {
    color: var(--theme-text-color);
    background-color: var(--theme-highlight-color);
  }
}
