.canvas-modal.video-permission-modal {
  .canvas-modal__wrapper {
    background-color: var(--black);
  }

  .canvas-modal__header {
    border-bottom: 0;
  }

  .canvas-modal__close-btn {
    top: -110px;
    right: -110px;
    padding: 9px 8px 8px 9px;
    border: 2px solid var(--gray-800);
    background: var(--black);

    svg path {
      color: var(--white);
    }
  }

  .canvas-modal__body {
    border: 2px solid var(--gray-800);
    background-color: var(--black);
    color: var(--white);
  }

  .video-permission-modal__header {
    margin: 55px 0 35px;
    padding: 4px 8px;
  }

  .video-permission-modal__heading {
    margin-bottom: 18px;
    font-size: 24px;
    font-weight: bold;
  }

  .video-permission-modal__sub-heading {
    margin: 0 auto 18px;
    width: 270px;
  }

  .switch-svg svg {
    width: 50px;
    height: 50px;
    padding: 10px 12px 14px;
    margin-bottom: 15px;
    color: var(--black);
    background-color: var(--white);
    border-radius: 50%;
  }

  .video-permission-modal__link {
    color: var(--theme-highlight-color);
    text-decoration: underline;
  }

  .video-permission-modal__continue-btn {
    font-size: 21px;
    padding: 12px 24px;
    background-color: var(--theme-base-color);
    color: var(--theme-text-color);
    width: 100%;
  }
}
