.branched-node {
  position: relative;
  width: 315px;
  max-height: 60px;
  min-height: 60px;
}

.branched-node__card {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 60px;
  max-height: 60px;
  padding: 8px 16px 8px 24px;
  border: 2px solid var(--white);
  border-radius: 12px;
  background-color: var(--gray-200);

  &--selected {
    background-color: var(--white);
    border-color: var(--theme-highlight-color);
  }
}

.branched-node__header {
  color: var(--black);
}

.branched-node__card-label {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 16px;
}

.branched-node__delete-action {
  height: 30px;
  width: 30px;
  margin-left: auto;
  flex-shrink: 0;
  border-radius: 30px;
  border: 2px solid var(--gray-200);
  background-color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--gray-800);
}

.branched-node__delete-btn {
  padding: 12px 16px;
  width: 100%;
  border-radius: 8px;
  background-color: var(--theme-warning-color);
  color: var(--white);
  font-size: 12px;
  line-height: 1;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  margin-bottom: 8px;
}
