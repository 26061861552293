.uploading-video-modal__header {
  display: flex;
  justify-content: center;
  padding: 15px 21px;
}

.uploading-video-modal__heading {
  font-size: 22px;
  font-weight: 600;
}

.uploading-video-modal__body {
  min-width: 564px;
  padding: 24px;
  display: flex;
  align-items: flex-start;
}

.uploading-video-modal__loading {
  position: relative;
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 24px;
}

.loading__icon {
  display: grid;
  place-items: center;
  width: 80px;
  height: 80px;
  background-color: #e0e7f2;
  border-radius: 50%;

  svg {
    font-size: 2.5em;
  }
}

.loading__loading-bar {
  width: 100%;
  height: 24px;
  padding: 1px;
  border-radius: 16px;
  border: 2px solid var(--gray-200);
}

.loaded-bar {
  --width: 50%;
  width: var(--width);
  height: 100%;
  transition: width 0.5s ease-in;
  background-color: var(--theme-highlight-color);
  border-radius: 16px;
}
