// .header {
//   display: flex;
//   padding-left: 10px;
//   min-height: 50px;

//   background: #fff;
//   box-shadow: 1px 7px 10px 0 #e3e3e3;

//   .header-item {
//     display: flex;
//     align-items: center;
//     width: 50%;
//   }

//   .header-logo {
//     width: 30px;
//     height: auto;
//     object-fit: contain;
//   }

//   .header-title {
//     padding-left: 10px;
//     margin-left: 8px;

//     font-weight: 600;

//     border-left: 2px solid #e0e7f1;
//   }
// }

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 60px;
  padding-inline: 20px;
  position: absolute;
  z-index: 1000;
  width: 100%;
}

.headerTitle {
  display: flex;
  align-items: center;
}

.header__title-section {
  display: flex;
}
.header__back-icon {
  color: #808080;
  cursor: pointer;
}

.headerTitle__left {
  color: gray;
  margin-inline: 0.2rem;
}

.header__actions-section {
  display: flex;
  width: 320px;
  justify-content: space-between;
}

.zoomButtons {
  display: flex;
  filter: drop-shadow(-2px 2px 5px lightgray);
  -webkit-filter: drop-shadow(-2px 2px 5px lightgray);
  button {
    background-color: rgb(248, 248, 248);
    padding: 5px 14px;
    font-size: 1.4rem;
  }
}
.zoomButtons__btn-left {
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  border-right: 1px solid #eeeeee;
}

.zoomButtons__btn-right {
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  border-left: 1px solid #eeeeee;
}

.action__button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 9px 18px;
  display: flex;
  border-radius: 18px;
  background-color: rgb(248, 248, 248);
  color: rgb(14, 14, 14);
  filter: drop-shadow(-2px 2px 5px lightgray);
  -webkit-filter: drop-shadow(-2px 2px 5px lightgray);
  svg {
    margin-right: 4px;
  }
}

.action__button--dark {
  background-color: rgb(14, 14, 14);
  color: rgb(248, 248, 248);
}

.save-notifications {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 12px;
}

.save-notifications__indicator {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 8px;
  &--idle {
    background-color: gray;
  }
  &--saving {
    background-color: orange;
  }

  &--success {
    background-color: greenyellow;
  }
  &--error {
    background-color: orangered;
  }
}

.save-notifications__msg {
  color: gray;
}

.fixed-foot {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 1rem;
  z-index: 100000;
}
