.labelled-switch {
  position: relative;
  display: inline-flex;
  height: 24px;
  min-width: 48px;
  color: var(--white);
  background-color: var(--gray-300);
  border-radius: 32px;
  display: flex;
  justify-content: center;
  align-items: center;

  &--enabled {
    background-color: var(--theme-highlight-color);
  }
}

.labelled-switch__label {
  padding-right: 12px;
  padding-left: 28px;
  font-size: 12px;
  line-height: 1;
  text-transform: uppercase;

  .labelled-switch--enabled & {
    padding-left: 12px;
    padding-right: 28px;
  }
}

.labelled-switch__thumb {
  position: absolute;
  height: 16px;
  width: 16px;
  border-radius: 16px;
  background-color: var(--white);
  transition: left 100ms ease;
  top: 4px;
  left: 4px;

  .labelled-switch--enabled & {
    left: calc(100% - 20px);
  }
}
