.add-branch-modal__header {
  padding: 4px 8px;
}

.add-branch-modal__heading {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 2px;
}

.add-branch-modal__body {
  min-height: 300px;
  padding: 24px;
  display: flex;
  align-items: center;
}

.add-branch-modal__choice-list {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  gap: 12px;
}

.add-branch-modal__choice-btn {
  padding: 16px 24px;
  width: 100%;
  border: 2px solid var(--gray-200);
  border-radius: 12px;
  text-align: left;
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.08);

  &:hover,
  &:focus {
    border: 2px solid var(--theme-highlight-color);
    box-shadow: none;
  }

  &.selected {
    background-color: var(--theme-highlight-color);
    color: var(--theme-text-color);
    box-shadow: none;
  }
}
.add-branch-modal__heading-note {
  color: #919191;
  margin-top: 15px;
  a {
    text-decoration: underline;
    color: var(--theme-highlight-color);
  }
}

.add-branch-modal__continue-btn {
  font-size: 21px;
  padding: 12px 24px;
  background-color: var(--theme-base-color);
  color: var(--theme-text-color);
  width: 100%;
}
