.node-card {
  --card-min-height: 450px;
  --card-min-width: 315px;

  position: relative;
  display: flex;
  flex-direction: column;
  padding: 10px 10px;
  width: var(--card-min-width);
  min-height: var(--card-min-height);
  background-color: var(--white);
  border-radius: 8px;
  border: 2px solid transparent;
  box-shadow: 0px 0px 7px 2px #e0e7f2;
  transition: 0.3s ease;

  .selected &,
  &:hover,
  &:active,
  &:focus {
    box-shadow: none;
    border-color: var(--theme-highlight-color);
  }
}
