.tooltip-menu {
  position: relative;
  z-index: 1000000;
}

.tooltip-menu__content {
  min-width: 175px;
  max-width: 400px;
  width: max-content;
  background-color: var(--white);
  border: 2px solid var(--gray-200);
  border-radius: 8px;
  transform: scale(0.85);
  box-shadow: 0 4px 8px 2px rgb(0 0 0 / 8%);
}

.tooltip-menu__arrow {
  position: absolute;
  background: var(--white);
  width: 24px;
  height: 24px;
  transform: rotate(-45deg);
  clip-path: polygon(0 0, 0% 100%, 100% 0);
  border: 2px solid var(--gray-200);
}

.tooltip-menu__wrapper {
  position: relative;
  border-radius: 8px;
  background-color: var(--white);
}

.tooltip-menu__header {
  position: relative;
  padding: 4px 24px;
  border-bottom: 1px solid var(--gray-200);
  font-size: 12px;
  line-height: 1;
  color: var(--gray-300);
  text-transform: uppercase;
  text-align: center;
}

.tooltip-menu__header-back-nav {
  position: absolute;
  padding: 0 4px;
  left: 4px;
  color: var(--theme-highlight-color);
}

.tooltip-menu__footer {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  overflow: hidden;
}

.tooltip-menu__body {
  padding: 8px 12px;
  font-size: 12px;
  color: var(--black);
}

.tooltip-menu__option {
  padding: 4px 8px;
  width: 100%;
  display: flex;
  align-items: baseline;
  background-color: transparent;
  border: none;
  text-align: left;
  border: none;
  border-bottom: 1px solid var(--gray-200);
  white-space: nowrap;
  color: var(--black);

  &:hover {
    background-color: #ecf4ff;
  }
}

.tooltip-menu__option-icon {
  padding-right: 8px;
  display: flex;
  align-items: center;
  font-size: 12px;

  svg {
    width: 12px;
    height: 12px;
  }
}

.tooltip-menu__action-btn {
  padding: 4px 8px;
  width: 100%;
  border: none;
  font-size: 12px;
  line-height: 1;
  color: var(--theme-text-color);
  text-transform: uppercase;

  &--primary {
    background-color: var(--theme-highlight-color);
  }

  &--secondary {
    background-color: var(--gray-800);
  }
}
