.outcome-node__card {
  display: flex;
  align-items: center;
  justify-content: center;
}

.outcome-card-side-action {
  --node-card-side-size: 130px;
  position: absolute;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: 16px;
  padding-bottom: 0;
  margin-top: 20px;
  width: var(--node-card-side-size);
  height: var(--node-card-side-size);
  border-radius: 40px;
  z-index: 1;

  .tooltip-menu__trigger {
    &::after {
      content: "";
      z-index: -1;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: var(--gray-200);
      clip-path: polygon(169px 0, 0% 169px, 100% 100%);
      transform: rotate(45deg);
      border-radius: 40px;
    }
  }
}

.next-step-popup {
  color: var(--theme-highlight-color) !important;
  background-color: white;
  border-radius: 50%;
}
