.timer {
  font-size: 75px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10000;
  color: red;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 9px 12px;
}

.green-badge {
  position: absolute;
  width: 40px;
  height: 40px;
  // background-color: green;
  border-radius: 50%;
  top: 20px;
  left: 20px;
  svg {
    color: lightgreen !important;
    font-size: 2.5rem;
  }
  p {
    color: lightgreen;
  }
}

.canvas-modal.video-recording-modal {
  display: flex;
  justify-content: flex-end;
  z-index: 1111;

  .canvas-modal__inner {
    padding: 0 16px;
  }

  .recorder {
    background-color: black;
    height: 100vh;
    width: 100vw;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    transform: rotateY(180deg);
    // object-fit: cover;
  }
  // Blurry side panels
  $b: 27em; // width of panels
  .canvas-modal__overlay {
    position: relative;
    width: 100%;
    height: 100vh;

    border: solid $b rgba(#000, 0.03);
    border-top: 0;
    border-bottom: 0;

    &:before {
      content: "";
      position: absolute;
      z-index: -1;
      top: 0;
      right: -$b;
      bottom: 0;
      left: -$b;
      background: rgba(0, 0, 0, 0.5);
      background-clip: border-box;
      border: inherit;
      border-color: transparent;
      clip-path: inset(0);
    }
  }
  // Blurry side panels

  .canvas-modal__header {
    border-bottom: 0;
  }

  .canvas-modal__close-btn {
    top: 6%;
    right: -100%;
    border: 2px solid var(--gray-800);
    color: white;
    background-color: transparentize($color: #000000, $amount: 0.3);
    z-index: 90000;
  }

  .canvas-modal__body {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
    color: var(--white);
    background-color: transparent;
    border: 2px solid var(--gray-800);
    border-radius: 0;
    box-shadow: none;
  }

  .video-controls-svg {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .setting-svg {
    & > svg {
      width: 22px;
      height: 22px;
      padding: 14px;
      margin: 0 20px 15px;
      color: var(--gray-200);
      background: rgba(0, 0, 0, 0.5);
      border-radius: 50%;
      border: 2px solid var(--gray-200);
    }
  }

  span,
  svg {
    color: white;
  }

  .record-svg svg {
    width: 45px;
    height: 45px;
    padding: 14px;
    margin: 0 22px 15px 0;
    color: var(--red-700);
    background: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    border: 2px solid var(--gray-200);
  }
}

#notes {
  width: 90%;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 9px 12px;
  margin-block: 1rem;
  font-size: 13px;
  padding: 1rem;
  border-radius: 9px;
  &::-webkit-scrollbar {
    width: 2px; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }
  &::-webkit-scrollbar-thumb {
    background: var(--gray-200);
  }
}
