.loader {
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 1000;
  width: 100%;
  left: 0;
  position: absolute;
  z-index: 10000;
}
.hide {
  display: none;
}
