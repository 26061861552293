.multiple-outcome-modal {
  .canvas-modal__body {
    min-width: 560px;
  }
}

.multiple-outcome-modal__header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 8px 8px;

  .multiple-outcome-modal__back-button {
    color: var(--body-color);
    font-weight: 600;

    &:hover {
      color: var(--theme-highlight-color);
    }
  }
}

.multiple-outcome-modal__heading {
  flex: 1;
  font-size: 24px;
  font-weight: bold;
  padding-right: 57px;
}

.multiple-outcome-modal__body {
  display: flex;
  align-items: center;
  min-height: 150px;
  padding: 24px;
}

.multiple-outcome-modal__content {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  .multiple-outcome-modal__placeholder {
    padding-top: 50px;
  }

  .text-content {
    padding-top: 20px;
    color: var(--gray-800);
  }

  .multiple-outcome-modal__text-content-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h2 {
      font-weight: 800;
    }
  }
}

.multiple-outcome-modal__continue-btn {
  font-size: 21px;
  padding: 12px 24px;
  background-color: var(--theme-base-color);
  color: var(--theme-text-color);
  width: 100%;
  height: 52px;

  svg {
    padding-left: 8px;
  }
}

.multiple-outcome-modal__cta-btn {
  padding: 8px;
  margin-top: 30px;
  font-size: 14px;
  font-weight: 600;
  color: var(--body-color);
  border-radius: 18px;
  background-color: transparent;
  border: 2px solid var(--gray-200);

  &:hover {
    color: var(--theme-highlight-color);
    border: 2px solid var(--theme-highlight-color);
  }
}

.has-outcome {
  align-items: flex-start;

  .multiple-outcome-modal__content {
    align-items: flex-end;
  }

  .multiple-outcome-modal__cta-btn {
    margin-top: 0;
  }

  .multiple-outcome-modal__choice-btn {
    padding: 16px 24px;
    margin-top: 15px;
    width: 100%;
    border: 2px solid var(--gray-200);
    border-radius: 12px;
    text-align: left;
    box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.08);

    &:hover,
    &:focus {
      border: 2px solid var(--theme-highlight-color);
      box-shadow: none;
    }

    &.selected {
      background-color: var(--theme-highlight-color);
      color: var(--theme-text-color);
      box-shadow: none;
    }
  }

  .multiple-outcome-modal__choice {
    padding: 16px 24px;
    margin-top: 15px;
    width: 100%;
    border: 2px solid var(--gray-200);
    border-radius: 12px;
    text-align: left;
    box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.08);
  }

  .multiple-outcome-modal__content {
    // padding-bottom: 200px;
  }
}

.multiple-outcome-modal__footer {
  min-height: 52px;
}
