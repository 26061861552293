.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  width: auto;
  height: 100%;

  border: 0;
  cursor: pointer;
}

.btn-holder {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.btn-blue {
  font-weight: 500;
  color: #fff;

  background: #3d4cee;
}

.btn-gray {
  color: #000;

  background: #f6f8fb;
}

.btn-black {
  color: #fff;

  background: #000;
}

.btn-bold {
  font-weight: 600;
}

.btn-icon {
  padding-right: 5px;
}
