.side-switch {
  position: relative;
  display: inline-flex;
  height: 28px;
  min-width: 32px;
  color: var(--white);
  background-color: var(--gray-300);
  border-radius: 32px;
  display: flex;
  justify-content: center;
  align-items: center;

  &--enabled {
    background-color: var(--theme-highlight-color);
  }
}

.side-switch__label {
  padding-right: 6px;
  padding-left: 14px;
  font-size: 6px;
  line-height: 1;
  text-transform: uppercase;

  .side-switch--enabled & {
    padding-left: 6px;
    padding-right: 14px;
  }
}

.side-switch__thumb {
  position: absolute;
  height: 8px;
  width: 8px;
  border-radius: 16px;
  background-color: var(--white);
  transition: left 100ms ease;
  top: 4px;
  left: 4px;

  .side-switch--enabled & {
    left: calc(100% - 10px);
  }
}
