.map-outcome-modal {
  --canvas-modal-min-w: 980px;
  user-select: none;
  display: flex;
  flex-direction: column;
}

.map-outcome-modal__header {
  padding: 16px 8px 8px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.map-outcome-modal__heading {
  font-size: 24px;
  font-weight: bold;
  width: 60%;
  text-align: center;
}

.map-outcome-modal__body {
  flex: 1;
}

.map-outcome-modal__table {
  width: 100%;
  height: 100%;
  min-height: 400px;

  td {
    padding: 0;
    border: 1px solid var(--gray-200);
    vertical-align: top;

    &:first-child {
      border-left: none;
    }
  }
}

.map-outcome-modal__save-btn {
  display: flex;
  gap: 8px;
  justify-content: center;
  background-color: var(--theme-base-color);
  color: var(--theme-text-color);
  padding: 12px;
  width: 100%;
}

.map-outcome-modal__table-header {
  text-align: center;

  td {
    width: 33.3333%;
    border-top: none;
    padding: 8px 8px;
    font-weight: bold;
    text-transform: uppercase;
  }
}

.map-outcome-modal__answer-list {
  list-style: none;
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.map-outcome-modal__answer-item {
  display: flex;
  gap: 8px;
  padding: 8px;
  border-radius: 12px;
  border: 2px solid var(--gray-200);
  background-color: var(--white);
  box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.08);

  &--dragging {
    opacity: 0.5;
  }

  &--drag-item {
    box-shadow: none;
    opacity: 0.75;
  }
  &.highlighted {
    border-color: var(--theme-highlight-color);
  }
}

.map-outcome-modal__answer-badge {
  flex-shrink: 0;
  width: 36px;
  height: 36px;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  color: var(--white);
  background-color: var(--badge-color, var(--theme-highlight-color));
  font-size: 18px;
  line-height: 1;
}

.blue {
  background-color: #0170e1 !important;
}
.green {
  background-color: #64d581 !important;
}
.teal {
  background-color: #2cd6c7 !important;
}
.purple {
  background-color: #8780ed !important;
}

.map-outcome-modal__answer-label {
  margin: auto 0;
  width: 100%;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.map-outcome-modal__outcome-list {
  list-style: none;
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.map-outcome-modal__outcome-item {
  padding: 12px;
  border: 2px solid var(--gray-200);
  border-radius: 12px;
  border-style: dashed;
  background-color: var(--white);
  box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.08);

  &--active {
    border-color: var(--theme-highlight-color);
  }
}

.map-outcome-modal__outcome-label {
  font-weight: bold;
}

.map-outcome-modal__outcome-subtitle {
  font-size: 14px;
}

.map-outcome-modal__outcome-mapped-list {
  display: flex;
  margin-top: 6px;
  flex-wrap: wrap;
  gap: 4px;
  min-height: 25px;
}

.map-outcome-modal__outcome-mapped-item {
  height: 25px;
  width: 25px;
  position: relative;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  color: var(--white);
  background-color: var(--badge-color, var(--theme-highlight-color));
  font-size: 14px;
  line-height: 1;
}

.map-outcome-modal__delete-container {
  padding: 8px;
  padding-top: 0;
}

.map-outcome-modal__delete-wrapper {
  min-height: 100px;
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
  border: 2px solid transparent;
  border-radius: 12px;
  border-style: dashed;

  &--active {
    border-color: red;
  }
}

.map-outcome-modal__delete-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  background-color: var(--gray-200);
  border-radius: 48px;
  font-size: 24px;
}

.map-outcome-modal__delete-label {
  max-width: 175px;
  color: var(--gray-300);
}

// .map-outcome-modal__question-item {
//   border-bottom: 1px solid var(--gray-200);
// }

.map-outcome-modal__question-btn {
  display: flex;
  gap: 12px;
  width: 100%;
  min-height: 70px;
  padding: 16px;
  border-bottom: 1px solid var(--gray-200);
  text-align: left;
  // &:focus,
  &.active {
    background-color: var(--theme-highlight-color);
    color: white;
  }
}

.map-outcome-modal__question-label {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  max-width: 275px;
}

.map-outcome-modal__question-accordion-btn {
  margin: auto 0;
  padding: 4px;
  border-radius: 24px;
  color: var(--gray-800);

  &:hover {
    background-color: var(--gray-100);
  }
}

.map-outcome-modal__question-sub-branch-list {
  border-left: 4px solid var(--theme-highlight-color);
}

.mapped-answer-settings-btn {
  position: absolute;
  right: -3px;
  top: -8px;
  height: 15px;
  width: 15px;
  border-radius: 30px;
  border: 2px solid var(--gray-200);
  background-color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--gray-800);
  z-index: 10;
  svg {
    height: 10px;
    width: 10px;
  }
}

.map-outcome-modal__main {
  // min-height: 80vh;
  display: flex;
  flex-direction: column;
  overflow-y: scroll I !important ;
}

.map-outcome-modal__main::-webkit-scrollbar {
  width: 5px;
}

.map-outcome-modal::-webkit-scrollbar-track {
  overflow: hidden;
}

.map-outcome-modal__main::-webkit-scrollbar-thumb {
  background: darkgray;
  border-radius: 10px;
}
.map-outcome-modal__main::-webkit-scrollbar-corner {
  background: red;
}
