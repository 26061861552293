.outcome-modal {
  .canvas-modal__body {
    min-width: 560px;
  }
  &__content-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-block: 1rem;
    // justify-content: center;
  }

  &__icon-wrapper {
    width: 30%;

    svg {
      padding: 0.5rem;
      border-radius: 50%;
      background: #e0e7f2;
      width: 1.9em;
      height: 1.5em;
      font-size: 1.5em;
      margin-inline: auto;
      display: block;
      // margin-left: 2.rem;
    }
  }

  &__content {
    margin-bottom: 60px;
    // padding-left: 2rem;

    button {
      margin-bottom: 10px;
    }
  }
}

.outcome-modal__header {
  padding: 16px 8px 8px;
}

.outcome-modal__heading {
  font-size: 24px;
  font-weight: bold;
}

.outcome-modal__body {
  min-height: 150px;
  padding: 24px;
  display: flex;
  align-items: center;
}

.outcome-modal__content {
  flex: 1 0 auto;
}

.outcome-modal__content h2 {
  color: var(--theme-highlight-color);
  // font-weight: 600;
  font-size: 1.4rem;
  text-align: center;
}

.outcome-modal__content p {
  font-size: 12px;
  text-align: center;
}

.outcome-modal__continue-btn {
  font-size: 21px;
  padding: 12px 24px;
  background-color: var(--theme-base-color);
  color: var(--theme-text-color);
  width: 100%;
  height: 52px;
}

.outcome-modal__footer {
  min-height: 52px;
}
