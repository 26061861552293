*,
::before,
::after {
  border-width: 0;
  border-style: solid;
}

:where(html) {
  line-height: 1.5;
  -webkit-text-size-adjust: 100%;
  -moz-tab-size: 4;
  tab-size: 4;
}

:where(body) {
  margin: 0;
  line-height: inherit;
}

:where(hr) {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

:where(h1),
:where(h2),
:where(h3),
:where(h4),
:where(h5),
:where(h6) {
  font-size: inherit;
  font-weight: inherit;
}

:where(a) {
  color: inherit;
  text-decoration: inherit;
}

:where(small) {
  font-size: 80%;
}

:where(sub),
:where(sup) {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

:where(sub) {
  bottom: -0.25em;
}

:where(sup) {
  top: -0.5em;
}

:where(table) {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

:where(button),
:where(input),
:where(optgroup),
:where(select),
:where(textarea) {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

:where(button),
:where(select) {
  text-transform: none;
}

:where(button),
:where([type="button"]),
:where([type="reset"]),
:where([type="submit"]) {
  -webkit-appearance: button;
  background-color: transparent;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

:where(blockquote),
:where(dl),
:where(dd),
:where(h1),
:where(h2),
:where(h3),
:where(h4),
:where(h5),
:where(h6),
:where(hr),
:where(figure),
:where(p),
:where(pre) {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol,
ul,
menu {
  list-style: none;
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
}

:where(button),
:where([role="button"]) {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

:where(img),
:where(svg),
:where(video),
:where(canvas),
:where(audio),
:where(iframe),
:where(embed),
:where(object) {
  display: block;
  vertical-align: middle;
}

:where(img),
:where(video) {
  max-width: 100%;
  height: auto;
}
