.outcome-node-add-step__tooltip {
  color: var(--white);
  padding: 4px 16px;
  background-color: var(--black);
  text-transform: uppercase;
  border-radius: 32px;
  white-space: nowrap;

  .arrow {
    position: absolute;
    transform: rotate(-45deg);
    background-color: var(--black);
    height: 16px;
    width: 16px;
    left: -2px;
    top: 5px;
    z-index: -1;
  }
}
