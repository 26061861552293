.add-video-modal__header {
  padding: 4px 8px;
}

.add-video-modal__heading {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 2px;
}

.add-video-modal__body {
  min-width: 560px;
  min-height: 300px;
  padding: 24px;
  display: flex;
  align-items: center;
}

.add-video-modal__content {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  gap: 12px;

  &-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
}

.add-video-modal__choice-btn {
  padding: 28px 34px;
  width: 100%;
  border: 2px solid var(--gray-200);
  border-radius: 12px;
  text-align: left;
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.08);

  &:hover,
  &:focus {
    border: 2px solid var(--theme-highlight-color);
    box-shadow: none;

    svg path {
      fill: var(--theme-highlight-color);
    }
  }

  &.selected {
    background-color: var(--theme-highlight-color);
    color: var(--theme-text-color);
    box-shadow: none;
  }
}

.add-video-modal__icon-wrapper {
  width: fit-content;

  svg {
    padding: 18px 16px;
    border-radius: 48%;
    background: #e0e7f2;
  }
}

.add-video-modal__text-content-wrapper {
  width: 70%;
  text-align: center;

  h2 {
    margin-bottom: 5px;
    color: var(--theme-highlight-color);
    font-size: 24px;
    font-weight: bold;
  }
}

.add-video-modal__continue-btn {
  font-size: 21px;
  padding: 12px 24px;
  background-color: var(--theme-base-color);
  color: var(--theme-text-color);
  width: 100%;
}
