.edgebutton {
  width: 30px;
  height: 30px;

  background-color: white;
  border: 1px solid #fff;
  cursor: pointer;
  border-radius: 50%;
  font-size: 20px;
  line-height: 1;
  user-select: none;
  color: #3d4cee;
  text-align: center;
  margin-inline: auto;
}

.edgebutton:hover {
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.08);
}

.edgebutton-foreignobject div.wrapper {
  background: transparent;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  min-height: 40px;
  position: relative;
  & > div {
    margin-left: 5px;
  }
}
