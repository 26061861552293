.small-label-input {
  min-width: 100px;
  font-size: 10px !important;
}
.disabled,
.disabled * {
  cursor: not-allowed;
}
.mainNode-wrapper {
  // position: absolute;
  // margin: 15rem 4rem 4rem;
  // z-index: 8;
  cursor: initial;
}

.mainNode-step-title > div {
  position: relative;
}

.mainNode-step-title > div > input {
  position: absolute;
  top: -20px;
  width: 50px;
  font-size: 12px;
  border: 2px solid var(--theme-highlight-color);
  box-shadow: none;

  &:focus-visible {
    outline: 0;
  }
}

.main-node-flow {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 10px;
  background-color: var(--white);
  border-radius: 8px;
  border: 2px solid transparent;
  box-shadow: 0px 0px 7px 2px var(--gray-200);
  transition: 0.3s ease;
  &.overlay {
    background-color: transparent;
    background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.7707457983193278) 0%,
      rgba(0, 85, 102, 0) 38%,
      rgba(0, 212, 255, 0) 100%
    );
  }

  &:hover,
  &:active,
  &:focus {
    border-color: var(--theme-highlight-color);
  }

  .simplebar-content {
    height: 100%;
  }

  &__title,
  &__title-input {
    width: 100%;
    border: 2px solid transparent;
    outline: none;
    border-radius: 4px;
    font-size: 25px;
    line-height: 1.1;
    // white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    line-clamp: 2;
    -webkit-line-clamp: 2;
  }
  &__title:empty:before {
    content: "Optional title/question";
  }

  &__title-input {
    &:focus {
      border-color: var(--gray-200);
    }
  }

  &__avatar {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    &.full {
      object-fit: cover;
    }
  }

  &__video-btn {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 55px;
    height: 55px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    transform: translate(-50%, -50%);
    background-color: var(--white);
    border: 2px solid transparent;
    border-radius: 50%;
    font-size: 1.25rem;
    color: var(--theme-highlight-color);
    box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.08);
    &.play {
      background-color: rgba(0, 0, 0, 0.3);
      color: white;
      border: 1px solid white;
    }
  }

  &_btnWrapper {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 8px 0;
  }

  &_line {
    width: 35%;
    height: 0;
    padding: 0;

    border-radius: 8px;
    background-color: #fff;
    border: 2px solid #e0e7f2;
  }

  &__footer {
    margin-top: auto;
  }

  &__auto-advance {
    display: flex;
    gap: 8px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    p {
      color: var(--gray-800);
    }

    &-icon {
      color: var(--gray-800);
    }
  }
}

.main-node-flow__wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.next-step-popup {
  color: var(--theme-highlight-color) !important;
  &:hover {
    background-color: var(--theme-highlight-color) !important;
    color: white !important;
  }
}

.main-node-flow__content {
  position: relative;
  flex: 0 0 auto;
  margin-top: 16px;
  min-height: 200px;
  z-index: 0;
}

.main-node-flow__choice-add-btn {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--white);
  border: 1px solid var(--gray-200);
  border-radius: 50%;
  color: var(--theme-highlight-color);
  text-align: center;

  &:hover {
    box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.08);
  }
}

.main-node-flow__choice-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.transparent {
  background-color: rgba(0, 0, 0, 0.4) !important;
  color: var(--white);
}

.main-node-choice {
  display: flex;
  width: 100%;
  padding: 8px 4px;

  font-size: 16px;
  font-weight: 400;
  text-align: left;
  border-radius: 8px;
  background-color: var(--white);
  border: 2px solid var(--gray-200);

  &:hover,
  &:focus,
  &:active {
    border-color: var(--theme-highlight-color);
  }

  &__label {
    padding: 5px;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
  }

  &__actions {
    flex-shrink: 0;
    margin-left: 4px;
    display: flex;
    align-items: center;
    gap: 2px;
  }

  &__action {
    height: 24px;
    width: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--gray-300);

    &:hover {
      color: var(--theme-highlight-color);
    }
  }

  &__active-setting {
    margin-left: auto;
    padding-left: 4px;
    font-size: 12px;
    color: var(--theme-highlight-color);
  }

  &__edit-input {
    width: 100%;
    padding: 13px 8px;
    border-radius: 8px;
    background-color: var(--white);
    border: 2px solid var(--gray-200);
  }

  &.continue-btn {
    background-color: var(--theme-highlight-color);
    color: var(--white);
    margin-top: 16px;
    font-weight: 500;
    &:hover {
      border-color: var(--gray-200);
    }
    & button {
      cursor: not-allowed;
    }
  }
}

.main-node-text-response {
  position: relative;
  border-radius: 8px;
  border: 2px solid var(--gray-200);
  overflow: hidden;
  padding-right: 16px;

  &:focus-within {
    border: 2px solid var(--theme-highlight-color);
  }

  &__input {
    margin: 0;
    width: 100%;
    height: auto;
    margin: 0;
    padding: 10px;
    resize: none;
    outline: none;
  }

  &__trash-btn {
    position: absolute;
    padding: 2px;
    top: 8px;
    right: 8px;
    color: var(--gray-800);
    font-size: 0.75rem;

    &:hover {
      color: var(--theme-highlight-color);
    }
  }

  &__submit {
    position: absolute;
    right: 8px;
    bottom: 8px;
    padding: 5px 15px;
    font-size: 10px;
    font-weight: 500;
    color: var(--white);
    letter-spacing: 1px;
    text-transform: uppercase;
    background: var(--theme-highlight-color);
    border-radius: 6px;
  }
}

.main-node-settings {
  &-btn {
    position: absolute;
    right: -15px;
    top: -15px;
    height: 30px;
    width: 30px;
    border-radius: 30px;
    border: 2px solid var(--gray-200);
    background-color: var(--white);
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--gray-800);
    z-index: 10;
  }

  &__delay-body {
    margin-top: 8px;
    display: flex;
    justify-content: center;
  }

  &__delete-btn {
    padding: 12px 16px;
    width: 100%;
    border-radius: 8px;
    background-color: var(--theme-warning-color);
    color: var(--white);
    font-size: 12px;
    line-height: 1;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    margin-bottom: 8px;
  }
}

.node-type-none {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  .main-node-flow__content {
    flex: 1;
  }
}

.node-type-open-ended {
  & .main-node-flow__footer {
    height: 160px;
    display: flex;
    align-items: end;
    justify-content: center;
  }
}

.video-node {
  .main-node-flow__title {
    color: var(--white);
    border-color: transparent;
  }
  .main-node-flow__auto-advance * {
    color: var(--white);
  }
}
