.input-text-field__label {
  display: inline-block;
  padding-left: 8px;
  margin-bottom: 12px;
}

.input-text-field__input {
  display: block;
  width: 100%;
  padding: 16px;
  border: 2px solid var(--gray-200);
  border-radius: 16px;
  box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.08);

  &.has-error {
    border-color: red;
  }

  &--small {
    padding: 8px 8px;
    border-radius: 8px;
    font-size: 12px;
  }

  &:hover,
  &:focus {
    border: 2px solid var(--theme-highlight-color);
    box-shadow: none;

    &.has-error {
      border-color: red;
      outline: none;
    }
  }
}
