.upload-video-modal__header {
  display: flex;
  justify-content: center;
  padding: 15px 21px;
}

.upload-video-modal__heading {
  font-size: 22px;
  font-weight: 600;
}

.upload-video-modal__body {
  min-width: 564px;
  min-height: 480px;
  padding: 24px;
  display: flex;
  align-items: flex-start;
}

.upload-video-modal__setting-list {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  gap: 12px;
  height: 100%;
}

.upload-video-modal__video-drag-field {
  position: relative;
  display: flex;
  padding: 64px;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 432px;
  gap: 24px;
  border: 3px dashed var(--gray-200);
  border-radius: 12px;
  cursor: pointer;

  &:hover,
  &.active {
    border: 3px dashed var(--theme-highlight-color);
  }
  &.error {
    border: 3px dashed var(--red-700);
  }
}

.video-drag-field__icon {
  display: grid;
  place-items: center;
  width: 100px;
  height: 100px;
  background-color: #e0e7f2;
  border-radius: 50%;

  svg {
    font-size: 3em;
  }
}

.video-drag-field__text {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 12px;

  h2 {
    font-size: 24px;
    font-weight: 600;
    color: var(--theme-highlight-color);
  }

  a {
    text-decoration: underline;
    color: var(--theme-highlight-color);
  }
}

.video-drag-field__help {
  position: absolute;
  bottom: 12px;
  right: 12px;
  width: 32px;
  height: 32px;
  display: grid;
  place-items: center;
  border-radius: 50%;
  border: 2px solid var(--gray-200);
}

.upload-video-modal__footer {
  display: flex;
}

.upload-video-modal__save-btn {
  display: flex;
  gap: 8px;
  justify-content: center;
  background-color: var(--theme-base-color);
  color: var(--theme-text-color);
  padding: 12px;
  width: 100%;

  span {
    font-size: 21px;
    letter-spacing: 1.47px;
  }

  &:nth-child(1) {
    border-right: 1px solid #65696d;
  }
}
