:root {
  --body-color: #273241;
  --theme-base-color: #000;
  --theme-highlight-color: #3d4cee;
  --theme-text-color: #fff;
  --theme-warning-color: var(--red-700);

  --white: #fff;
  --black: #000;
  --gray-100: #f2f5f9;
  --gray-200: #e0e7f2;
  --gray-300: #bfc2c6;
  --gray-800: #9399a0;
  --red-700: #e1200a;
}
