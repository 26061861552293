.outcome-node {
  position: relative;
}

.outcome-node__card {
  --card-min-height: 0;
  height: 75px;
}

.outcome-node__body {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.outcome-node__label {
  font-size: 24px;
  text-align: center;
}
