.listbox__wrapper,
%listbox__wrapper {
  position: relative;
}

.listbox__button,
%listbox__button {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 8px;
  background-color: var(--white);
  border: 2px solid var(--gray-200);
  padding: 8px 40px 8px 12px;
  text-align: left;
}

.listbox__label,
%listbox__label {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &-step {
    font-weight: bold;
  }
}

.listbox__button-icon,
%listbox__button-icon {
  position: absolute;
  padding-right: 4px;
  right: 2px;
  display: flex;
  align-items: center;
  pointer-events: none;
  font-size: 16px;
  color: var(--gray-300);

  svg {
    width: 16px;
    height: 16px;
  }
}

.listbox__options,
%listbox__options {
  position: absolute;
  width: 100%;
  margin-top: 2px;
  max-height: 200px;
  padding: 2px 0;
  background-color: var(--white);
  border: 1px solid var(--gray-200);
  border-radius: 2px;
  overflow: auto;
  z-index: 10;
}

.listbox__option,
%listbox__option {
  position: relative;
  padding: 8px 12px;
  cursor: pointer;

  &--active {
    background-color: var(--gray-200);
  }

  &--selected {
    color: var(--white);
    background-color: var(--theme-highlight-color);
  }
}
