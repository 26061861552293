.optin-node {
  position: relative;
  cursor: initial;
}

.optin-node__card {
  --card-min-height: 400px;
}

.optin-node__title-input {
  width: 100%;
  height: 40px;
  font-size: 16px;
  border: none;
  box-shadow: none;

  &:focus-visible {
    outline: 0;
  }
}

input.optin-node__input {
  width: 100%;
  padding: 11.8px 10px 10px;
  border: 2px solid var(--gray-200);
  border-radius: 12px;
  background-color: var(--gray-100);
  display: flex;
  align-items: baseline;
  gap: 8px;
  box-shadow: 0 1px 4px 1px rgb(0 0 0 / 8%);

  &:focus-visible {
    outline: 0;
  }
}

.optin-node__title {
  width: 100%;
  margin-top: 12px;
  margin-bottom: 12px;
  font-size: 24px;
  line-height: 1.5;
  font-weight: bold;
}

.optin-node__description {
  margin-bottom: 12px;
}

.optin-node__label {
  font-size: 24px;
  text-align: center;
}

.optin-node__footer {
  margin-top: auto;
}

.optin-node__continue-btn {
  width: 100%;
  padding: 10px 0;
  font-size: 14px;
  color: var(--theme-text-color);
  border-radius: 8px;
  background-color: var(--theme-base-color);
  border: 2px solid transparent;

  &:hover,
  &:focus,
  &:active {
    border-color: var(--theme-highlight-color);
  }
}

.optin-node__inputs {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.optin-node-input {
  padding: 8px 12px;
  border: 2px solid var(--gray-200);
  border-radius: 12px;
  background-color: var(--gray-100);
  display: flex;
  align-items: baseline;
  gap: 8px;
  box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.08);

  &--hidden {
    box-shadow: none;
    opacity: 0.4;
  }
}

.optin-node-input__icon {
  color: var(--gray-300);
  font-size: 18px;
}

.optin-node-input__label {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.optin-node-input__actions {
  margin-left: auto;
  display: flex;
  gap: 4px;
}

.optin-node-input__action {
  height: 24px;
  width: 32px;
  padding: 2px 4px;
  font-size: 18px;

  &--hidden {
    opacity: 0.2;
  }
}

.optin-node__terms {
  margin: 14px 0;
  padding: 2px 14px 2px 8px;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 13px;

  a {
    text-decoration: underline;
  }
}

.optin-node__terms-checkbox {
  align-self: flex-start;
  flex-shrink: 0;
  height: 18px;
  width: 18px;
  border-radius: 4px;
  border: 2px solid var(--gray-300);
}
.main-node-flow__title-input__edit {
  width: 80%;
  background-color: transparent;
  outline: 0;
  height: 100%;
}
