.new-interface-modal {
  .canvas-modal__body {
    min-width: 560px;
  }
}

.new-interface-modal__header {
  padding: 16px 8px 8px;
}

.new-interface-modal__heading {
  font-size: 24px;
  font-weight: bold;
}

.new-interface-modal__body {
  min-height: 150px;
  padding: 24px;
  display: flex;
  align-items: center;
}

.new-interface-modal__content {
  flex: 1 0 auto;
}

.new-interface-modal__continue-btn {
  font-size: 21px;
  padding: 12px 24px;
  background-color: var(--theme-base-color);
  color: var(--theme-text-color);
  width: 100%;
  height: 52px;
}

.new-interface-modal__footer {
  min-height: 52px;
}
